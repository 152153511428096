// <-----------------------------  Author:- Rahul kumar ----------------------------------->
import React, { useState, useEffect, useMemo,useLayoutEffect } from "react";
import TextInput from "./ClientsReusableComponent/TextInput";
const ClientArchitect = ({ onDataChange,initialValues,reset }) => {
  const [clientFormData, setClientFormData] = useState({
    architectName: "",
    architectNumber: "",
    contractorName: "",
    contractorNumber: "",
  });

  useLayoutEffect(() => {
    setClientFormData({
      architectName: "",
    architectNumber: "",
    contractorName: "",
    contractorNumber: "",
    });
  }, [reset]);
  const [click, setClick] = useState({});

  const hadleInputChnage = (e) => {
    const { name, value } = e.target;
    if(name==='architectName' || name==='contractorName' ){
      const hasNumbers = /\d/.test(value)
      if(hasNumbers){
        return 
      }
     }
     if(name==="contractorNumber" && value?.length>10){
      return;
     }
     if(name==="architectNumber" && value?.length>10){
      return;
     }
    
    setClientFormData({ ...clientFormData, [name]: value });
  };

  const handleClick = (e) => {
    const { name } = e.target;
    setClick({ ...click, [name]: true });
  };

  const handleClickFalse = (e) => {
    const { name } = e.target;
    setClick({ ...click, [name]: false });
  };

  useEffect(() => {
    onDataChange(clientFormData);
  }, [clientFormData]);
useMemo(()=>{
  setClientFormData(initialValues)
},[initialValues])
  return (
    <div className="client-architect">
      <h5 className="client-form-details-heading">Architect Details</h5>
      <hr className="client-form-hr" />
      <div className="client-architect-container client-form-input-wrapper">
        <div>
          <TextInput
            label={"Architect Name"}
            name={"architectName"}
            onFocus={handleClick}
            value={clientFormData?.architectName}
            onChange={hadleInputChnage}
            click={click.architectName}
            onBlur={handleClickFalse}
          />
        </div>
        <div>
          <TextInput
            label={"Phone Number"}
            name={"architectNumber"}
            onFocus={handleClick}
            value={clientFormData?.architectNumber}
            onChange={hadleInputChnage}
            click={click.architectNumber}
            onBlur={handleClickFalse}
            type={"number"} 
          />
        </div>
        <div>
          <TextInput
            label={"Contractor Name"}
            name={"contractorName"}
            onFocus={handleClick}
            value={clientFormData?.contractorName}
            onChange={hadleInputChnage}
            click={click.contractorName}
            onBlur={handleClickFalse}
            
          />
        </div>
        <div>
          <TextInput
            label={"Phone Number"}
            name={"contractorNumber"}
            onFocus={handleClick}
            value={clientFormData?.contractorNumber}
            onChange={hadleInputChnage}
            click={click.contractorNumber}
            onBlur={handleClickFalse}
            type={"number"} 
          />
        </div>
      </div>
    </div>
  );
};

export default ClientArchitect;
