import React from 'react'
import ErectionEgineerCard from "../Component/ErectionEngineerSubComponent/ErectionEngineerCard"

const ErectionEngineers = () => {
    return (
        <div className='main-container erectionContainer'>
            <ErectionEgineerCard />
        </div>
    )
}

export default ErectionEngineers